import React from 'react'
import { Link } from 'gatsby'
import logo from '../assets/logo-02.svg'

import { rhythm } from '../utils/typography'
import Helmet from 'react-helmet'

require('prismjs/plugins/line-numbers/prism-line-numbers.css')
require('../assets/prism-theme-evening.css')

class Layout extends React.Component {
  render() {
    const { title, children, center } = this.props
    return (
      <React.Fragment>
        <Helmet
          meta={[
            {
              name: 'google-site-verification',
              content: '1c_OfyQS3405H0FHJ88NrGmS4tfe2hCQrgaiQsCUgr4',
            },
          ]}
        />
        <div
          style={{
            borderTop: '3px #FC9E49 solid',
          }}
        />

        <div
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: rhythm(24),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
            textAlign: !center ? 'left' : 'center',
          }}
        >
          <header
            style={{
              textAlign: 'center',
            }}
          >
            <Link
              style={{
                boxShadow: 'none',
                textDecoration: 'none',
                color: 'inherit',
              }}
              to={'/'}
            >
              <img src={logo} alt={title} style={{ height: '4em' }} />
            </Link>
            <link
              async
              href="https://fonts.googleapis.com/css?family=Montserrat:900"
              rel="stylesheet"
            />
          </header>
          <main role="main">{children}</main>
        </div>
      </React.Fragment>
    )
  }
}

export default Layout
