import React from 'react'
import Layout from '../components/Layout'
import broken from '../assets/broken.svg'

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} center>
        <h1> O rage ! O despair !</h1>
        <p>You just hit a route that doesn&#39;t exist... </p>
        <img src={broken} alt="broken robot" />
      </Layout>
    )
  }
}

export default NotFoundPage
